import { Icon } from "@/components/Icon";
import { BackgroundColorsEnum } from "enums/backgroundColors";
import IconsEnum from "enums/icons";
import Headlines from "../Headlines/Headlines";

const ProcessSteps = ({ content, appearance, header }) => {
  const { frameClass } = appearance || {};
  const getGridClass = (content) => {
    switch (content.length) {
      case 1:
        return "grid-cols-1";
      case 2:
        return "grid-cols-2";
      case 3:
        return "grid-cols-3";
      case 4:
        return "xl:grid-cols-4 md:grid-cols-2";
      case 5:
        return "xl:grid-cols-5 md:grid-cols-3";
      case 6:
        return "xl:grid-cols-6 md:grid-cols-3";
      default:
        return "grid-cols-6 md:grid-cols-3";
    }
  };
  const getHeadlineColor = (frameClass) => {
    switch (frameClass) {
      case "1":
        return "text-white";
      default:
        return "text-blue-dark";
    }
  };
  const gridClass = `grid-cols-${content.length}`;
  return (
    <div
      className={`${BackgroundColorsEnum[frameClass]}  w-full h-full lg:py-16 py-10`}
    >
      {header && (
        <h2
          className={`${getHeadlineColor(
            frameClass
          )} text-center lg:text-6xl text-5xl lg:mb-9 mb-8`}
        >
          {header}
        </h2>
      )}
      <div
        className={`container grid ${getGridClass(
          content
        )} grid-cols-1 lg:gap-9 gap-8 `}
      >
        {content.map((item, index) => (
          <Tile
            key={index}
            index={index}
            parentBGColor={frameClass}
            {...item}
          />
        ))}
      </div>
    </div>
  );
};

export default ProcessSteps;

const Tile = ({ ...props }) => {
  const { index } = props;
  const { icon, text, header, parentBGColor } = props.content;
  const textClass = parentBGColor === 3 ? "!text-white" : "!text-blue-dark";
  const iconClass = parentBGColor !== 3 ? "!text-white" : "!text-blue-dark";
  console.log("proppy", props);
  return (
    <div className="w-full h-full flex lg:flex-col lg:justify-start justify-center lg:items-center items-start gap-4">
      <div
        className={`flex h-16 w-16 items-center justify-center rounded-full relative ml-6 lg:ml-0 bg-blue-light`}
      >
        <Icon type={IconsEnum[icon]} className={`w-10 h-10  ${iconClass}`} />
        <span
          className={`flex font-bold justify-center items-center text-white bg-orange-light h-10 w-10 rounded-full absolute top-0 -left-7`}
        >
          {index + 1}.
        </span>
      </div>
      <div
        className={`lg:pl-0 lg:pb-0  lg:pr-0 pl-6 pb-8 pr-4 w-2/3 lg:w-full `}
      >
        <p className="lg:text-center font-bold text-pretty lg:text-2xl text-lg lg:py-6 lg:mb-0 mb-3">
          {header}
        </p>
        <p className={` text-sm lg:text-base `}>{text}</p>
      </div>
    </div>
  );
};
